<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense">
        </ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/tabs/tabReproductor')"
          src="assets/ICONOS/close.svg"></ion-icon>
        <p class="tabTitle">ID de sucursal</p>
        <ion-text class="title_desc" v-if="sucursalesReqAux.length > 0 || loaderFunc == true">
          <p>Seleccione el ID de sucursal:</p>

          <div class="filter_input">
            <span class="inpIcon">
              <ion-icon class="i_search" @click="filtrar" src="assets/ICONOS/search.svg"></ion-icon>
            </span>
            <ion-input @keyup="filtrar" ref="f_buscador" class="inpText" type="search" value=""
              placeholder="Busca sucursal por nombre"></ion-input>
            <span class="inpclose">
              <ion-icon class="i_close" @click="cerrarfiltro" src="assets/ICONOS/close_f.svg"></ion-icon>
            </span>
          </div>
          <ion-text class="title_desc" v-show="sucursalesReq.length == 0 && filtroB != ''">
            <p>No se encontraron coincidencias...</p>
          </ion-text>
        </ion-text>
        <ion-text class="title_desc" v-else>
          <h5>Sin IDs de sucursal disponibles...</h5>
          <p>Por el momento usted no cuenta con identificadores de sucursal activos,
            si necesita agregar alguno, favor de ponerse en contacto
            con alguno de nuestros asesores.
          </p>
          <footerContactanos></footerContactanos>
        </ion-text>
        <div class="out_loader" v-if="(sucursalesReqAux.length <= 0 && loaderFunc == true) || loaderFunc == true">
          <div class="loader"></div>
        </div>

        <ion-list lines="inset" class="link_items">
          <!--clase para seleccionar item: "selected" -->
          <ion-item class="link_item" v-for="sucursal in sucursalesReqPaginado" :key="sucursal.identifier"
            v-bind:class="sucursal.identifier == currentSucursal ? 'selected link_item item ios in-list ion-focusable hydrated' : 'link_item item ios in-list ion-focusable hydrated'">
            <ion-grid class="left_div_link">
              <ion-row class="play_button_link">
                <ion-col class="div">
                  <ion-icon class="play" v-if="currentSucursal != sucursal.identifier"
                    v-on:click="confirmarCambioSucursal(sucursal.identifier)"
                    src="assets/ICONOS/reproductor-blanco.svg"></ion-icon>
                  <ion-icon class="pause" v-else src="assets/ICONOS/i-pausa.svg"></ion-icon>
                </ion-col>
              </ion-row>
              <ion-row class="text_p">
                <ion-col class="div">
                  <h3 class="text" :title="sucursal.identifier">{{ sucursal.identifier }}</h3>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </ion-list>
        <ion-text class="fin_de_la_lista" v-show="finaldelista && pagina >= 2">
          <small>- fin de la lista -</small>
        </ion-text>
      </div>

      <ion-infinite-scroll @ionInfinite="loadData($event)" threshold="100px" id="infinite-scroll"
        :disabled="isDisabled">
        <ion-infinite-scroll-content loading-spinner="crescent" loading-text="Cargando...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script lang="js">
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import footerContactanos from './footerContactanos.vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from './header_toolbar.vue';
export default defineComponent({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, footerContactanos },
  data() {
    return {
      sucursals: [{
        id: 1,
        name: "Lista de Éxitos",
        imagePath: "lista-de-exitos.png"
      }],
      sucursalesReqAux: [],
      sucursalesReqPaginado: [],
      sucursalesReq: [],
      pagina: 1, //Este siempre al inicio es 1no
      items_x_pagina: 20, //Cantidad de items por pagina
      items_x_pagina_base: 20, //Cuando se cargue o recargue la pagina items_x_pagina siempre regresa a esta variable 
      finaldelista: false,
      list: [],
      currentSucursal: "",
      loaderFunc: true,
      filtroB: '',
      clientData: {},
    }
  },
  methods: {
    loadData(event) {
      // console.log('cargar_paginacion:: Cargando...');
      if (!this.finaldelista) {
        setTimeout(() => {
          this.cargar_paginacion(false);
          event.target.complete();
        }, 1000);
      } else {
        // console.log("cargar_paginacion:: Llego al final de la lista");
        event.target.complete();
      }
    },
    async filtrar() {
      this.list = []; // Rainiciamos la lisa auxiliar
      this.filtroB = (this.$refs.f_buscador.value).toLowerCase(); // get texto del filtro
      console.log('============');
      console.log('  FILTRO - >' + this.filtroB);
      if (this.filtroB == "") {// si el filtro es vacio
        this.sucursalesReq = this.sucursalesReqAux;//lista auxiliar del array original
        console.log('Sin Filtro');
        this.cargar_paginacion(true);
      } else {// si el filtro no esta vacio
        this.sucursalesReqAux.forEach((value, index) => {//foreach de la lista original
          var valueAux = value.identifier.toLowerCase();
          //console.log('  VALOR - >'+valueAux);
          if (valueAux.includes(this.filtroB)) {
            this.list.push(value);// creamos una lista temporal filtrada
          }
        });
        console.log(this.list);
        this.sucursalesReq = this.list;// igualamos la lista temporal al array que se muestra
        this.cargar_paginacion(true);
      }
      console.log('============');
    },
    async cerrarfiltro() {
      this.sucursalesReq = [];
      this.filtroB = "";
      this.loaderFunc = true;
      this.$refs.f_buscador.value = "";
      setTimeout(function () {
        this.sucursalesReq = this.sucursalesReqAux;
        this.cargar_paginacion(true);
        this.loaderFunc = false;
      }.bind(this), 300);
      console.log('Filtro Borrado');
    },
    async cargar_paginacion(iniciandoCiclo) {
      this.finaldelista = false;
      // console.log("cargar_paginacion::==========");
      if (iniciandoCiclo) {
        this.pagina = 1;
        this.items_x_pagina = this.items_x_pagina_base;
        this.sucursalesReqPaginado = [];
      }
      var item_inicial = this.items_x_pagina - this.items_x_pagina_base;
      // console.log("cargar_paginacion::Cargando CICLO d Items==========");
      // console.log("cargar_paginacion::iniciandoCiclo: "+iniciandoCiclo);
      // console.log("cargar_paginacion::finaldelista: "+this.finaldelista);
      // console.log("cargar_paginacion::pagina: "+this.pagina);
      // console.log("cargar_paginacion::item_inicial: "+item_inicial);
      // console.log("cargar_paginacion::items_x_pagina: "+this.items_x_pagina);
      // console.log("cargar_paginacion::items_x_pagina_base: "+this.items_x_pagina_base);
      // console.log("cargar_paginacion::Cargando CICLO d Items==========");
      for (let index = item_inicial; index < this.items_x_pagina; index++) {
        //const element = array[index];
        //console.log("cargar_paginacion:::: "+index);
        if (this.sucursalesReq[index]) {
          this.sucursalesReqPaginado.push(this.sucursalesReq[index]);
        } else {
          this.finaldelista = true;
        }
      }
      if (!this.finaldelista) {
        this.pagina = this.pagina + 1;
      }
      this.items_x_pagina = this.items_x_pagina + this.items_x_pagina_base;
      //console.log("cargar_paginacion::sucursalesReqPaginado=========================");
      //console.log(this.sucursalesReqPaginado);
      // console.log("cargar_paginacion::==========");
    },
    async loadClient() {
      const ret = await Storage.get({ key: "clientData" });
      if (ret.value) this.clientData = JSON.parse(ret.value);
      else console.log("no se pudo consultar clientData del Storage");
      const clientParameter = { id: this.clientData.link ? this.clientData.link : "" };
      axios.post("https://vybroo.net/client", clientParameter)
        .then(response => {
          this.loaderFunc = false;
          // console.log(response);
          this.sucursalesReq = response.data.identifiers;
          this.sucursalesReqAux = this.sucursalesReq;
          /*this.sucursalesReqAux.forEach((value, index) => {//foreach de la lista original
            var valueAux=value.identifier.toLowerCase();
            //console.log('  VALOR - >'+valueAux);
            if (valueAux.includes(this.filtroB)) {
              this.list.push(value);// creamos una lista temporal filtrada
            }
          });*/
          // Agregamos elementos unicos de la pagina
          this.cargar_paginacion(true);

          console.log(this.sucursalesReq);
          console.log("sucursalesReq=========================");
        })
        .catch(error => { 
          this.loadClientVBack(clientParameter, error);
        });
      this.getClientData("sucursalSeleccionada");
    },
    async loadClientVBack(clientParameter, loadClienterror) {
      axios.post("/transmito/vbk_get_client/?link="+clientParameter.id)
        .then(response => {
          this.loaderFunc = false;
          // console.log(response);
          this.sucursalesReq = response.data.identifiers;
          this.sucursalesReqAux = this.sucursalesReq;
          this.cargar_paginacion(true);

          console.log(this.sucursalesReq);
          console.log("sucursalesReq=========================");
        })
        .catch(error => {
          console.error("AudiosDex: There was an error!",loadClienterror);
          console.error("vBACKAudiosDex: There was an error!", error);
        });
      this.getClientData("sucursalSeleccionada");
    },
    async confirmarCambioSucursal(nuevaSucursal) {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Deseas seleccionar esta sucursal?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                console.log('Cambiar sucursal pulsado, nueva sucursal: ' + nuevaSucursal);
                this.cambioDeSucursal(nuevaSucursal);
                this.getClientData("sucursalSeleccionada");
                // this.$router.replace("/tabs/tabReproductor");
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();
    },

    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    cambioDeSucursal(nuevaSucursal) {
      Storage.set({
        key: "sucursalSeleccionada",
        value: JSON.stringify(nuevaSucursal),
      })
        .then
      console.log("Asignado a storage exitosamente - " + nuevaSucursal);
    },
    async getClientData(keyName) {
      const ret = await Storage.get({ key: keyName });
      this.currentSucursal = this.isJson(ret.value);
      console.log("Recuperado de storage - " + this.currentSucursal);
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
  },
  mounted: function () {
    this.loadClient();
    this.getFullClientData();
  },
  computed: {
    getImgUrl(pic) {
      // return require('~@/assets/sucursals/'+pic);
      return (pic);
    }
  }
})
</script>


<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}

.headerTop {
  background-color: black;
}

.play_button_link {
  float: left;
  font-size: 30px;
  line-height: 30px;
  margin: 0px 12px 0px 0px;
}

.link_items {
  padding: 10px;
  background-color: transparent;
}

.link_items .selected {
  --background: linear-gradient(to right, #04949c, #00a8ad) !important;
}

.fin_de_la_lista {
  text-align: center;
  display: block;
}

.fin_de_la_lista small {
  color: #ccc;
  display: block;
  margin: 20px 0px;
}

.link_item {
  padding: 5px 0;
  background-color: transparent;
  max-width: 450px;
  margin: auto;
  --border-radius: 10px;
}

.link_item h2 {
  font-weight: bold;
}

.link_items .play {
  display: block;
  cursor: pointer;
}

.link_items .pause {
  display: none;
}

.link_items .selected .play {
  display: none;
}

.link_items .selected .pause {
  display: block;
}

.link_items .play,
.link_items .pause {
  margin: 6px auto !important;
  font-size: 36px;
  line-height: 36px;
}

.link_items .link_item {
  --background: #1e1e1e;
}
.link_item .text_p .div { 
  width: 100%;
}

.link_item .text_p {
  overflow: hidden;
}

.link_item .text_p .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px 0px;
}

.link_item .text_p:hover .text{
  display: inline-flex;
  position: relative;
  animation: 3s linear 0s infinite move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}

@keyframes move {
  0% {
    transform: translateX(0%);
    left: 0%;
  }

  15% {
    transform: translateX(0%);
    left: 0%;
  }

  70% {
    transform: translateX(-100%);
    left: 100%;
  }

  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.left_div_link {
  width: 100%;
}



@media only screen and (min-width: 850px) {
  .link_item {
    width: calc(33.33% - 10px);
    float: left;
    margin: 5px;
  }

  .link_items .play,
  .link_items .pause {
    margin: 6px auto !important;
    font-size: 60px;
    line-height: 60px;
  }

  .play_button_link {
    float: none;
    margin: 0px;
  }

  .link_item .text_p .text {
    text-align: center;
  }

}

@media only screen and (min-width: 980px) {
  .link_item {
    width: calc(25% - 10px);
  }

  .link_item .text_p .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 380px) {
  .link_item .text_p .text {
    font-size: 16px;
  }
}
</style>
